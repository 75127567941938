




















import { defineComponent, ref } from '@vue/composition-api';
import { useI18n } from 'vue-i18n-bridge';
import * as _ from 'lodash';

export default defineComponent({
  name: 'SetupRepositoryNameControl',
  emits: ['input'],
  props: {
    value: String,
    githubOwner: String,
    label: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const repositoryName = ref(props.value);
    const validationProvider = ref(null);
    const messages = {
      regex: t('setup.forms.errors.invalidRepoName'),
    };

    const debounce = _.debounce(() => {
      (validationProvider.value as any).validate(repositoryName.value).then(({ valid }) => {
        emit('input', valid ? repositoryName.value : '');
      });
    }, 500);
    function syncValue() {
      debounce.cancel();
      debounce();
    }

    return {
      repositoryName,
      validationProvider,
      messages,
      syncValue,
    };
  },
});






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppExpansionPanel',
  props: {
    header: String,
    content: String,
    avatar: String,
    draggable: Boolean,
    selection: Boolean,
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"validationProvider",attrs:{"customMessages":_vm.messages,"name":_vm.label,"rules":{
    required: true,
    max: 255,
    existingGithubRepository: _vm.repositoryUrl,
  },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var pending = ref.pending;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.label,"loading":pending,"readonly":_vm.readonly,"outlined":""},model:{value:(_vm.repositoryUrl),callback:function ($$v) {_vm.repositoryUrl=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"repositoryUrl"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
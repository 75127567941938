































import { useI18n } from 'vue-i18n-bridge';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { useNewProjectsStore } from '@/stores/new-project';
import { githubSourceRepositoryToGithubUrl, githubUrlToGithubSourceRepository } from '@/model/storeApiConverter';
import AppGitHubRepositoryUrlControl from '@/components/wizard/forms/copyFromSourceRepositoryForm/GitHubRepositoryUrlControl.vue';
import AppRepositoryNameControl from '@/components/wizard/forms/copyFromSourceRepositoryForm/RepositoryNameControl.vue';
import { useGitHubStore } from '@/stores/github';
import { ProviderType } from 'ionos-space-api-v4';

export default defineComponent({
  name: 'AppChooseThirdPartyRepository',
  components: {
    AppRepositoryNameControl,
    AppGitHubRepositoryUrlControl,
  },
  props: {
    readonlySource: Boolean,
    sourceProperty: String,
  },
  setup(props) {
    const { tm } = useI18n();
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();
    const githubStore = useGitHubStore();

    const samples = tm('setup.sourceSelection.thirdPartyRepo.samples') as Array<{ text: string; value: string }>;
    const selectedSample = computed<string>(() => setupWizardStore.selectedSample);
    const sourceRepoStore =
      setupWizardStore.thirdPartyRepository.source &&
      githubSourceRepositoryToGithubUrl(setupWizardStore.thirdPartyRepository.source);
    const prefilledRepoUrl = props.sourceProperty || sourceRepoStore;

    if (prefilledRepoUrl) {
      const suffixRegex = /\.git$/;
      const foundSample = samples.find(
        (sample) => sample.value.replace(suffixRegex, '') === prefilledRepoUrl.replace(suffixRegex, '')
      );
      setupWizardStore.selectedSample = foundSample ? foundSample.value : '';
    }

    const sourceRepoUrl = ref(prefilledRepoUrl || selectedSample.value);

    const destinationRepositoryName = computed<string>({
      get() {
        return setupWizardStore.thirdPartyRepository.destination.repository.repo;
      },
      set(value) {
        setupWizardStore.thirdPartyRepository.destination.repository = {
          owner: githubStore.getOwner as string,
          repo: value,
          branch: 'main',
          type: ProviderType.GITHUB,
        };
        newProjectsStore.projectName = value;
      },
    });

    const keepPrivate = computed<boolean>({
      get() {
        return setupWizardStore.thirdPartyRepository.destination.privateRepo ?? false;
      },
      set(value) {
        setupWizardStore.thirdPartyRepository.destination.privateRepo = value;
      },
    });

    const getOwner = computed(() => githubStore.getOwner);

    const setSourceRepository = (url: string) => {
      const repoParts = githubUrlToGithubSourceRepository(url);
      setupWizardStore.thirdPartyRepository.source = repoParts ? repoParts : undefined;
    };

    const selectSample = (url: string) => {
      setupWizardStore.selectedSample = url;
      sourceRepoUrl.value = url;
      setSourceRepository(url);
    };

    return {
      sourceRepoUrl,
      destinationRepositoryName,
      keepPrivate,
      samples,
      selectedSample,
      setSourceRepository,
      selectSample,
      getOwner,
    };
  },
});















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppWizardTitle',
  props: {
    title: String,
    subtitle: Boolean,
    color: {
      type: String,
      default: 'accent',
    },
    url: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const rowClasses = computed<Record<string, boolean>>(() => ({
      'mb-8': !props.subtitle,
      'mb-4': props.subtitle,
    }));
    const titleClasses = computed<Record<string, boolean>>(() => ({
      'display-4': !props.subtitle,
      'display-3': props.subtitle,
    }));
    return {
      rowClasses,
      titleClasses,
    };
  },
});
















import router from '@/router/router';

import { GithubIdentity } from 'ionos-space-api-v4';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { githubUrlToGithubSourceRepository } from '@/model/storeApiConverter';
import { RepositoryPanel, useSetupWizardStore } from '@/stores/setup-wizard';
import AppExpansionPanel from '@/components/wizard/ExpansionPanel.vue';
import AppChooseOwnRepository from '@/components/wizard/steps/repository/ChooseOwnRepository.vue';
import AppChooseThirdPartyRepository from '@/components/wizard/steps/repository/ChooseThirdPartyRepository.vue';
import AppWizardTitle from '@/components/wizard/forms/title/WizardTitle.vue';
import { useNewProjectsStore } from '@/stores/new-project';
import { useGitHubStore } from '@/stores/github';

export default defineComponent({
  name: 'SetupChooseSource',
  components: {
    AppChooseThirdPartyRepository,
    AppChooseOwnRepository,
    AppExpansionPanel,
    AppWizardTitle,
  },
  setup() {
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();
    const githubStore = useGitHubStore();

    const repoUrl = typeof router.currentRoute.query?.repo === 'string' ? router.currentRoute.query?.repo : '';
    const presetRepo = ref(repoUrl);
    const githubAccount = computed<GithubIdentity | object>(() => githubStore.getAccount || {});
    const panels = computed<number>({
      get() {
        return setupWizardStore.selectedRepositoryPanel;
      },
      set(value) {
        setupWizardStore.selectRepositoryPanel(value);
      },
    });
    const sourceRepo = githubUrlToGithubSourceRepository(repoUrl);
    if (sourceRepo) {
      setupWizardStore.thirdPartyRepository.source = sourceRepo;
      setupWizardStore.selectRepositoryPanel(RepositoryPanel.ThirdParty);
    }

    const name = computed(() => newProjectsStore.projectName);

    // TODO: Why is this here?
    watch(name, () => {
      setupWizardStore.selectedProjectTypePanel = 0;
    });

    return {
      panels,
      presetRepo,
      githubAccount,
    };
  },
});





















import { defineComponent, computed } from '@vue/composition-api';
import { useGitHubStore } from '@/stores/github';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import AppLoadingSpinner from '@/components/shared/LoadingSpinner.vue';

export default defineComponent({
  name: 'AppChooseBranch',
  components: { AppLoadingSpinner },
  props: {
    repository: {
      type: String,
      default: '',
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const setupWizardStore = useSetupWizardStore();
    const githubStore = useGitHubStore();
    const branches = computed<string[] | undefined>(() => {
      return githubStore.branchesForFullName(githubStore.fullName(props.repository));
    });
    const selectedBranch = computed<string>({
      get() {
        return setupWizardStore.ownRepository.branch || '';
      },
      set(value) {
        selectBranch(value);
      },
    });

    const selectBranch = (branch: string) => {
      setupWizardStore.ownRepository.branch = branch;
      emit('change', branch);
    };

    return {
      branches,
      selectedBranch,
    };
  },
});

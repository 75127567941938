
























import * as _ from 'lodash';
import { computed, defineComponent, ref } from '@vue/composition-api';
import i18n from '@/plugins/i18n';

export default defineComponent({
  name: 'SetupGitHubRepositoryUrlControl',
  props: {
    value: String,
    label: String,
    readonly: Boolean,
  },
  emits: ['input'],
  setup(props, { emit }) {
    const validationProvider = ref(null);
    const messages = {
      regex: i18n.t('setup.forms.errors.sourceRepositoryNoGithubLink').toString(),
    };
    const repositoryUrl = computed<string>({
      get() {
        return props.value || '';
      },
      set(value) {
        syncValue(value);
      },
    });

    const syncValue = _.debounce((value: string) => {
      (validationProvider.value as any).validate(value).then(({ valid }) => {
        emitInput(valid ? value : '');
      });
    }, 500);

    const emitInput = (value: string) => {
      emit('input', value);
    };

    if (repositoryUrl.value) {
      syncValue(repositoryUrl.value);
    }

    return {
      repositoryUrl,
      messages,
      validationProvider,
    };
  },
});












































import { defineComponent, ref, computed } from '@vue/composition-api';
import { GithubRepository, ProviderType } from 'ionos-space-api-v4';
import { useI18n } from 'vue-i18n-bridge';
import { useNewProjectsStore } from '@/stores/new-project';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { useGitHubStore } from '@/stores/github';
import AppChooseBranch from '@/components/wizard/steps/repository/ChooseOwnRepository/ChooseBranch.vue';
import AppLoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { EnvVars } from '@/utils/env-vars';
import { storeToRefs } from 'pinia';

interface SelectItem {
  text: string;
  value: string;
  disabled: boolean;
}

export default defineComponent({
  name: 'AppChooseOwnRepository',
  components: {
    AppChooseBranch,
    AppLoadingSpinner,
  },
  setup() {
    const { t } = useI18n();
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();
    const githubStore = useGitHubStore();
    const loading = ref<boolean>(false);

    const { getAccount, getInstallationUrl, githubRepositories, installation } = storeToRefs(githubStore);

    const repositoriesForSelection = computed<SelectItem[]>(() =>
      githubRepositories.value.map(githubRepositoryToSelectItem)
    );
    const showAutocomplete = computed<boolean>(() => repositoriesForSelection.value.length > 5);

    const loadBranches = (repo: string) => {
      githubStore.loadOwnBranches(repo).then((branches?: string[]) => {
        const defaultBranch = githubStore.defaultBranchForRepo(repo);
        if (defaultBranch) {
          setupWizardStore.ownRepository.branch = defaultBranch;
        } else if (branches?.length === 1) {
          setupWizardStore.ownRepository.branch = branches[0];
        }
      });
    };

    const selectRepository = (repo: string) => {
      setupWizardStore.ownRepository = {
        owner: githubStore.getOwner as string,
        repo: repo,
        branch: 'main',
        type: ProviderType.GITHUB,
      };
      newProjectsStore.projectName = repo;
      loadBranches(repo);
    };

    const selectedRepository = computed<string>({
      get() {
        return setupWizardStore.ownRepository.repo || '';
      },
      set(value) {
        selectRepository(value);
      },
    });

    const githubRepositoryToSelectItem = (value: GithubRepository): SelectItem => {
      const repositoryNameWithoutRepoOwner = value.fullName.substring(value.fullName.indexOf('/') + 1);
      const appendix = value.inUse ? t('setup.ownRepositorySelection.repositoryInUseAppendix') : '';
      return {
        text: repositoryNameWithoutRepoOwner + appendix,
        value: repositoryNameWithoutRepoOwner,
        disabled: value.inUse,
      };
    };

    const githubManageInstallationUrl = computed(() =>
      installation?.value?.externalId ? EnvVars.getGithubManageInstallationUrl(installation.value.externalId) : ''
    );

    loading.value = true;
    githubStore.loadGithubRepositories().finally(() => {
      loading.value = false;
    });

    return {
      loading,
      selectedRepository,
      repositoriesForSelection,
      showAutocomplete,
      getAccount,
      getInstallationUrl,
      selectRepository,
      githubManageInstallationUrl,
    };
  },
});
